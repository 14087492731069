<template>
  <div
    ref="editableText"
    class="editable-text"
  >
    <!-- Display Mode -->
    <div
      v-if="!isEditing"
      class="d-flex align-items-center"
    >
      <span>{{ displayValue }}</span>
      <b-button
        aria-label="Edit"
        class="ml-auto px-1"
        variant="link"
        :disabled="disabled"
        @click.stop="enableEditing"
      >
        <feather-icon
          aria-hidden="true"
          icon="Edit2Icon"
        />
      </b-button>
    </div>

    <!-- Edit Mode -->
    <div
      v-else
    >
      <div class="d-flex align-items-center">
        <b-input-group>
          <b-form-input
            ref="inputField"
            v-model="currentValue"
            class="font-medium-1 py-0"
            aria-label="Edit text"
            @keyup.enter="save"
            @keyup.esc="cancel"
          />
          <template #append>
            <b-button
              v-b-tooltip.hover="{ title: 'Save', delay: { show: 600, hide: 0 } }"
              aria-label="Save"
              class="px-1"
              size="sm"
              variant="outline-primary"
              @click="save"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>
          </template>
        </b-input-group>
      </div>
      <small
        v-if="hasChanged"
        class="text-muted"
      >
        Previous value: {{ previousValue }}
      </small>
    </div>
  </div>
</template>

<script>
import { BFormInput, BButton, BInputGroup } from 'bootstrap-vue'

export default {
  name: 'EditableText',
  components: {
    BInputGroup,
    BFormInput,
    BButton,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    /**
     * When true, prevents the text from being edited
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      currentValue: this.value,
      previousValue: '',
    }
  },
  computed: {
    displayValue() {
      return this.value
    },
    hasChanged() {
      return this.currentValue !== this.previousValue
    },
  },
  watch: {
    value(newVal) {
      if (!this.isEditing) {
        this.currentValue = newVal
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    enableEditing() {
      this.isEditing = true
      this.currentValue = this.value
      this.previousValue = this.value
      this.$emit('editing', true)
      this.$nextTick(() => {
        if (this.$refs.inputField) {
          this.$refs.inputField.focus()
        }
      })
    },
    save() {
      if (this.currentValue !== this.value) {
        this.$emit('input', this.currentValue)
      }
      this.isEditing = false
      this.$emit('editing', false)
    },
    cancel() {
      this.currentValue = this.value
      this.isEditing = false
      this.$emit('editing', false)
    },
    handleClickOutside(event) {
      if (this.isEditing && !this.$refs.editableText.contains(event.target)) {
        this.cancel()
      }
    },
  },
}
</script>
