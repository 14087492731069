<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        spinner-variant="primary"
      >
        <b-card :title="`Manage ${incubator.title}`">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-between">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search Members"
                type="text"
              />
              <b-button
                v-b-modal.addMemberModal
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="d-inline-flex mr-1"
                variant="outline-success"
              >
                <feather-icon
                  class="mr-50"
                  icon="PlusIcon"
                />
                <span class="align-middle text-nowrap">Add Member</span>
              </b-button>
              <b-button
                v-b-modal.manageIncubator
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="d-inline-flex mr-1"
                variant="outline-primary"
              >
                <feather-icon
                  class="mr-50"
                  icon="SettingsIcon"
                />
                <span class="align-middle text-nowrap">Update Incubator Details</span>
              </b-button>
            </b-form-group>
          </div>
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="incubator.members"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'full_name' && orgAssocId === props.row.assoc_id">
                {{ props.formattedRow[props.column.field] }}
                <b-badge variant="light-secondary">You</b-badge>
              </span>
              <span
                v-else-if="props.column.field === 'designation'"
              >
                <EditableText
                  :value="props.row.designation"
                  :disabled="currentlyEditing !== null && currentlyEditing !== props.row.assoc_id"
                  :disabled-tooltip="currentlyEditing !== null ? 'Please complete the current edit first' : ''"
                  @input="e => editDesignation(props.row.assoc_id, e)"
                  @editing="isEditing => currentlyEditing = isEditing ? props.row.assoc_id : null"
                />
              </span>

              <span v-else-if="props.column.field === 'action'">
                <b-button
                  size="sm"
                  variant="flat-danger"
                  :disabled="orgAssocId === props.row.assoc_id"
                  @click="orgAssocId !== props.row.assoc_id ? deleteMember(props.row.assoc_id) : null"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="18"
                  />
                </b-button>
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
        <!-- Update Incubator details modal -->
        <b-modal
          id="manageIncubator"
          ok-title="Update"
          size="lg"
          title="Incubator Details"
          no-close-on-backdrop
          @ok="formSubmitted"
        >
          <validation-observer
            ref="incubatorBasicInfoRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="6"
              ><span class="d-block">Current Logo </span> <Promised
                v-if="incubator.logo"
                :promise="getLinkFromS3(incubator.logo)"
              >
                <template #pending>
                  <b-spinner />
                </template>
                <template #default="data">
                  <b-img
                    :src="data"
                    fluid
                    class="border img-fluid mb-1 p-50 rounded w-75"
                  />
                </template>
                <template #rejected>
                  <span>
                    No image uploaded.
                  </span>
                </template>
              </Promised></b-col>
              <b-col
                cols="6"
                class="align-content-center"
              >
                <!-- Styled -->

                <b-form-group
                  label="Upload New Logo"
                  label-for="programPoster"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="File"
                    rules="size:10000"
                  >
                    <b-form-file
                      v-model="incubator.file"
                      :state="errors.length > 0 ? false:null"
                      accept="image/jpeg, image/png, image/gif"
                      drop-placeholder="Drop file here..."
                      placeholder="Choose a file or drop it here..."
                      style="margin-bottom: 0.5rem;"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Incubator Name *"
                  label-for="incubator"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Incubator Name"
                    rules="required"
                  >
                    <b-form-input
                      id="incubator"
                      v-model="incubator.title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Incubator Name"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="incubator.description"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Incubator Description"
                      rows="2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Official Email *"
                  label-for="official_email"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Official Email"
                    rules="required"
                  >
                    <b-form-input
                      id="official_email"
                      v-model="incubator.official_email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="hello@yourbusiness.com"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Phone Number *"
                  label-for="url"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone Number"
                    rules="required"
                  >
                    <b-form-input
                      id="official_phone"
                      v-model="incubator.official_phone"
                      :state="errors.length > 0 ? false:null"
                      placeholder="+91-1234567890"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Website"
                  label-for="url"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Website"
                  >
                    <b-form-input
                      id="url"
                      v-model="incubator.url"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Incubator Website URL"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Industry"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                    label="Industry *"
                    label-for="industry"
                  >
                    <v-select
                      id="industry"
                      v-model="industryArray"
                      :options="industries"
                      placeholder="Select Industry"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Lifecycle Stage"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                    label="Lifecycle Stage *"
                    label-for="startup_stage"
                  >
                    <v-select
                      id="startup_stage"
                      v-model="startup_stageArray"
                      :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                      placeholder="Select Lifecycle Stage"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="City *"
                  label-for="city"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <b-form-input
                      id="city"
                      v-model="incubator.city"
                      :state="errors.length > 0 ? false:null"
                      placeholder="City"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="State"
                  label-for="state"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                  >
                    <b-form-input
                      id="state"
                      v-model="incubator.state"
                      :state="errors.length > 0 ? false:null"
                      placeholder="State"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <validation-provider
                    name="Country"
                  >
                    <b-form-input
                      id="country"
                      v-model="incubator.country"
                      placeholder="Country"
                      type="text"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-modal>
        <!-- Add member modal -->
        <b-modal
          id="addMemberModal"
          size="lg"
          title="Add Member"
          hide-footer
          no-close-on-backdrop
        >
          <b-row>
            <!-- Email input field -->
            <b-col>
              <b-form-group
                label="Email"
                label-for="member"
              >
                <b-overlay
                  :show="mutationLoading"
                >
                  <div class="bg-light rounded-sm">
                    <b-form-input
                      id="member"
                      v-model="member.email"
                      lazy
                      placeholder="Enter Email"
                      type="email"
                      @blur="fetchUser()"
                    />
                    <p
                      v-show="member.email && !mutationLoading"
                      class="px-1 py-50"
                    >Result:
                      <b-badge
                        v-if="searchData === false"
                        pill
                        variant="danger"
                      >No User Found!
                      </b-badge>
                      <b-badge
                        v-else-if="member.id && existingUser"
                        class="ml-25"
                        pill
                        variant="danger"
                      >
                        User already exists!
                      </b-badge>
                      <b-badge
                        v-else-if="!member.role || member.role === 'mentor' || member.role === 'startup'"
                        class="ml-25"
                        pill
                        variant="danger"
                      >
                        Invalid account type!
                      </b-badge>
                      <b-badge
                        v-else
                        class="text-capitalize"
                        pill
                        variant="primary"
                      >{{ searchData }}
                      </b-badge>
                    </p>
                  </div>
                </b-overlay>
              </b-form-group>
            </b-col>
            <!-- Designation input field -->
            <b-col>
              <b-form-group
                label="Designation"
                label-for="memberDesignation"
              >
                <b-form-input
                  id="memberDesignation"
                  v-model="member.designation"
                  placeholder="Designation of member"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <!-- Add member button -->
            <b-col cols="auto">
              <b-button
                :disabled="disableAddButton"
                class="mt-2"
                variant="outline-primary"
                @click="$bvModal.hide('addMemberModal'); addMember()"
              >
                <feather-icon
                  class="mr-25"
                  icon="PlusIcon"
                />
                Add
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BModal,
  BRow,
  BImg,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import { Promised } from 'vue-promised'
import { getUserData } from '@/utils/auth'
import { VueGoodTable } from 'vue-good-table'
import EditableText from '@/components/common/EditableText.vue'
import moment from 'moment-timezone'

export default {
  components: {
    EditableText,
    BSpinner,
    BBadge,
    BModal,
    BImg,
    BCard,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BFormFile,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    vSelect,
    Promised,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueGoodTable,
  },
  data() {
    return {
      mutationLoading: false,
      currentlyEditing: null,
      columns: [
        {
          label: 'Name',
          field: 'full_name',
        },
        {
          label: 'Designation',
          field: 'designation',
          tdClass: 'py-50',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Member Since',
          field(row) {
            if (!row.created_at) return '-'
            return moment(row.created_at).format('DD MMM YYYY')
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      searchData: '',
      member: {
        id: null,
        email: null,
        designation: null,
        role: '',
      },
      searchTerm: '',
      designation: '',
      pageLength: 5,
      incubator: {
        title: null,
        designation: null,
        description: null,
        official_email: null,
        official_phone: null,
        url: null,
        logo: null,
        industry: '',
        startup_stage: '',
        city: null,
        state: null,
        country: null,
        file: null,
        members: [],
      },
      industries: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      required,
      email,
    }
  },
  computed: {
    disableAddButton() {
      return !this.member.id || !this.member.role || this.existingUser || ['mentor', 'startup'].includes(this.member.role)
    },
    orgAssocId() {
      return getUserData().associatedOrgDetails.filter(org => org.role === 'superadmin')[0].id
    },
    existingUser() {
      return this.incubator.members.findIndex(e => e.id === this.member.id) !== -1
    },
    industryArray: {
      get() {
        // Convert the comma-separated string to an array
        return this.incubator.industry ? this.incubator.industry.split(',') : []
      },
      set(value) {
        this.incubator.industry = value.join(',')
      },
    },
    startup_stageArray: {
      get() {
        return this.incubator.startup_stage ? this.incubator.startup_stage.split(',') : []
      },
      set(value) {
        this.incubator.startup_stage = value.join(',')
      },
    },
  },
  methods: {
    formSubmitted() {
      this.updateIncubatorDetails()
      this.mutationLoading = true
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.incubatorBasicInfoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    mutationObject() {
      const {
        assoc_id, designation, members, users_associationtables, ...incubatorDetails
      } = this.incubator
      return incubatorDetails
    },
    async updateIncubatorDetails() {
      if (this.incubator.file) {
        try {
          const data = await this.uploadFile(this.incubator.file)
          this.incubator.logo = data
          this.mutateIncubatorDetails()
        } catch (error) {
          this.showAlert('Failed to upload file', 'danger')
        }
        return false
      }
      this.mutateIncubatorDetails()
      return true
    },
    mutateIncubatorDetails() {
      this.$apollo.mutate({
        mutation: gql`
      mutation($object: users_organizationtable_insert_input!) {
        insert_users_organizationtable_one(object: $object, on_conflict: {constraint: users_organizationtable_pkey, update_columns: [title, description, official_email,logo, official_phone, url, industry, startup_stage, city, state, country]} ) {
          id
        }
      }`,
        variables: { object: this.mutationObject() },
        update: () => {
          this.$apollo.queries.incubator.refetch()
          this.mutationLoading = false
          this.$bvModal.hide('manageIncubator')
          this.showAlert('Details updated successfully', 'success')
        },
      })
    },
    fetchUser() {
      if (!this.member.email) return false
      this.mutationLoading = true
      this.$apollo.query({
        query: gql`query options($q: String!){
              users_customuser(where: {email: {_ilike: $q}}) {
                      full_name
                      email
                      role
                      id
                    }
              }`,
        variables: { q: this.member.email },
      })
        .then(({ data }) => {
          this.mutationLoading = false
          this.searchData = !data.users_customuser[0] ? false : data.users_customuser[0].full_name
          this.member.id = data.users_customuser[0]?.id || null
          this.member.role = data.users_customuser[0]?.role
        })
      return true
    },
    addMember() {
      this.$apollo.mutate({
        mutation: gql`
          mutation InsertUserAssociation($organizationId: Int!, $designation: String!, $role: String!, $status: String!, $userId: Int!) {
            insert_users_associationtable_one(object: { organization_id: $organizationId, designation: $designation, role: $role, status: $status, user_id: $userId}) {
              id
            }
          }`,
        variables: {
          organizationId: this.incubator.id,
          designation: this.member.designation,
          role: 'superadmin',
          status: 'Invited',
          userId: this.member.id,
        },
        update: () => {
          this.member.id = null
          this.member.designation = null
          this.$apollo.queries.incubator.refetch()
        },
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error adding member',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      })
    },
    editDesignation(assocId, designation) {
      if (!designation) {
        this.currentlyEditing = null
        return this.showAlert('Designation cannot be empty', 'danger')
      }
      const oldDesignation = this.incubator.members.find(e => e.assoc_id === assocId).designation
      if (oldDesignation === designation) return false
      this.$apollo.mutate({
        mutation: gql`
          mutation updateDesignation($assocId: Int!, $designation: String!) {
            update_users_associationtable_by_pk(pk_columns: {id: $assocId}, _set: {designation: $designation}) {
              id
            }
          }
        `,
        variables: {
          assocId,
          designation,
        },
        // Show a toast if id is present in return and refetch
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          if (update_users_associationtable_by_pk.id) this.showAlert('Designation updated successfully', 'success')
          else this.showAlert('Failed to update designation', 'danger')
          this.$apollo.queries.incubator.refetch()
        },
      })
      return true
    },
    // Delete Member (set is_deleted to true)
    deleteMember(id) {
      // Confirm Delete (use this.$bvModal.msgBoxOk)
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this member?', {
        title: 'Confirm Delete',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.$apollo.mutate({
              mutation: gql`
          mutation ($Id: Int!){
            update_users_associationtable_by_pk(pk_columns: {id: $Id}, _set: {is_deleted: true}) {
              id
            }
          }`,
              variables: {
                Id: id,
              },
              update: () => {
                this.$apollo.queries.incubator.refetch()
              },
            })
          }
        })
    },
  },
  apollo: {
    incubator: {
      query() {
        return gql`
        query IncubatorMember ($Id: Int!) {
          users_associationtable_by_pk(id: $Id) {
            id
            designation
            users_organizationtable {
              id
              title
              description
              official_email
              official_phone
              url
              industry
              startup_stage
              city
              state
              country
              logo
              users_associationtables(where: {role: {_eq: "superadmin"}}) {
                id
                is_deleted
                designation
                created_at
                users_customuser {
                  id
                  full_name
                  email
                }
              }
            }
          }
        }`
      },
      variables() {
        return {
          Id: this.orgAssocId,
        }
      },
      update(data) {
        return ({
          assoc_id: data.users_associationtable_by_pk.id,
          designation: data.users_associationtable_by_pk.designation,
          ...data.users_associationtable_by_pk.users_organizationtable,
          members: data.users_associationtable_by_pk.users_organizationtable.users_associationtables.map(e => ({
            ...e.users_customuser,
            designation: e.designation,
            assoc_id: e.id,
            created_at: e.created_at,
          })),
        })
      },
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
